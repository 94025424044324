<template>
  <span>
    <template v-if="!minutes">
      <span class="text-h2">{{ currentValue }}</span>s
    </template>

    <template v-if="minutes">
      <template v-if="minutesValue > 0">
        <span class="text-h2">{{ minutesValue }}</span>min
      </template>
      <span class="text-h2">{{ secondsValue }}</span>s
    </template>
  </span>
</template>

<script>
export default {
  name: 'Countdown',

  props: ['value', 'minutes', 'stop', 'tick'],

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.clear();
        this.currentValue = newVal;

        if (!this.stop) {
          this.interval = setInterval(this.updateSeconds, 1000);
        }
      }
    },

    tick: {
      handler() {
        this.clear();
        this.currentValue = this.value;

        if (!this.stop) {
          this.interval = setInterval(this.updateSeconds, 1000);
        }
      }
    },

    stop: {
      immediate: true,
      handler(newVal) {
        this.clear();
        this.currentValue = this.value;

        if (!newVal) {
          this.interval = setInterval(this.updateSeconds, 1000);
        }
      }
    }
  },

  data() {
    return {
      interval: null,
      currentValue: 0
    };
  },

  beforeDestroy: function(){
    this.clear();
  },

  computed: {
    minutesValue() {
      return Math.floor(this.currentValue / 60);
    },

    secondsValue() {
      return Math.floor(this.currentValue % 60);
    }
  },

  methods: {
    updateSeconds() {
      if (this.currentValue > 0) {
        this.currentValue--;
      }
    },

    clear() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }
}
</script>
