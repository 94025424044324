<template>
  <v-row>
    <v-col cols="12">
      <v-btn v-if="!timerIsRunning && currentTrainingTime === 0 && !preTimeout" @click="setPreTimeout()" color="primary">Start <v-icon dark right>mdi-av-timer</v-icon></v-btn>   
      <v-btn v-if="!timerIsRunning && currentTrainingTime !== 0 && !preTimeout" @click="setPreTimeout()" color="primary">Resume <v-icon dark right>mdi-av-timer</v-icon></v-btn>   
      <v-btn v-if="!timerIsRunning && currentTrainingTime !== 0 && !preTimeout" @click="stopTimer()" color="warning">Clear</v-btn>
      <v-btn v-if="timerIsRunning || preTimeout" @click="stopTimer()" color="error">Stop</v-btn>   
      <v-btn v-if="(timerIsRunning || preTimeout) && currentTrainingTime !== 0" @click="pauseTimer()" color="primary">Pause</v-btn>

      <div class="mt-2" v-if="preTimeout">
        <v-icon class="text-h4 mb-5" color="black" left>mdi-av-timer</v-icon>
        <Countdown :value="10" :minutes="true" />
      </div>
    </v-col>

    <v-col cols="12" md="6">
      <v-card elevation="0" outlined>
        <v-card-title>{{ currentExcersizeData.title }}
          <template v-if="hasPause">
            (Break)
          </template>
        </v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Set</v-list-item-subtitle>
              <v-list-item-title><span class="text-h2">{{ timerPosition.set }}</span> / {{ currentExcersizeData.sets }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-subtitle>Rep</v-list-item-subtitle>
              <v-list-item-title><span class="text-h2">{{ timerPosition.rep }}</span> / {{ currentExcersizeData.reps }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-subtitle>Duration</v-list-item-subtitle>
              <v-list-item-title>
                <span :class="{'text-h2': this.timerPosition.duration === 1}">{{ currentExcersizeData.duration[0] }}</span>
                <span :class="{'text-h2': this.timerPosition.duration === 2}">{{ currentExcersizeData.duration[1] }}</span>
                <span :class="{'text-h2': this.timerPosition.duration === 3}">{{ currentExcersizeData.duration[2] }}</span>
                <span :class="{'text-h2': this.timerPosition.duration === 4}">{{ currentExcersizeData.duration[3] }}</span>    
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="6">
      <v-card elevation="0" outlined>
        <v-card-title>Time</v-card-title>
        <v-card-text>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Current duration</v-list-item-subtitle>
              <v-list-item-title>
                <Countdown :value="currentCountdown" :minutes="true" :stop="!timerIsRunning" :tick="timerPosition.rep" />
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-subtitle>End of training</v-list-item-subtitle>
              <v-list-item-title>
                <Countdown :value="trainingTime - currentTrainingTime" :minutes="true" :stop="!timerIsRunning" />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Countdown from './Countdown';
import Sounds from '../sounds.json';

export default {
  props: ['exercises'],

  components: {
    Countdown
  },

  data() {
    return {
      timerPosition: {
        exerciseIndex: 0,
        set: 1,
        rep: 1,
        duration: 1,
        pause: false,
      },

      timerTimout: null,
      pauseNotifyTimeout: null,
      preTimeout: null,
      audio: new Audio()
    };
  },

  computed: {
    currentExcersizeData() {
      const exercise = this.exercises[this.timerPosition.exerciseIndex];

      return (exercise) ? this.exersizeData(exercise) : undefined;
    },

    hasPause() {
      return this.timerPosition.pause;
    },

    trainingTime() {
      return this.exercises.reduce(this.accumulateExercisesTime, 0);
    },

    currentTrainingTime() {
      const exersizesUntilCurrentExercize = this.exercises.slice(0, this.timerPosition.exerciseIndex);
      const timeUntilCurrentExercize = exersizesUntilCurrentExercize.reduce(this.accumulateExercisesTime, 0);
      const currentExercise = this.currentExcersizeData;
      const repititionTime = currentExercise.duration.reduce((callback, item) => item + callback, 0);
      const timeUntilCurrentSet = (this.timerPosition.set - 1) * repititionTime * currentExercise.reps + (this.timerPosition.set - 1) * currentExercise.pause;
      const timeUntilCurrentRepition = repititionTime * (this.timerPosition.rep - 1);
      const timeUntilCurrentDuration = currentExercise.duration.reduce((callback, item, durationIndex) => {
        if (durationIndex < this.timerPosition.duration - 1 || this.timerPosition.pause) {
          return callback + item;
        }

        return callback;
      }, 0);

      return timeUntilCurrentExercize + timeUntilCurrentSet + timeUntilCurrentRepition + timeUntilCurrentDuration;
    },

    timerIsRunning() {
      return this.timerTimout != null;
    },

    currentCountdown() {
      if (this.currentExcersizeData) {
        if (this.timerPosition.pause) {
          return this.currentExcersizeData.pause;
        } else {
          return this.currentExcersizeData.duration[this.timerPosition.duration - 1];
        }
      }

      return 0;
    }
  },

  methods: {
    setPreTimeout() {
      this.makeSound();

      this.preTimeout = setTimeout(() => {
        this.clearPreTimeout();
        this.setNextTimer(true)
      }, 10000);
    },

    clearPreTimeout() {
      if (this.preTimeout) {
        clearTimeout(this.preTimeout);
        this.preTimeout = null;
      }
    },

    clearTimeout() {
      if (this.timerTimout) {
        clearTimeout(this.timerTimout);
        this.timerTimout = null;
      }
    },

    clearPauseNotifyTimeout() {
      if (this.pauseNotifyTimeout) {
        clearTimeout(this.pauseNotifyTimeout);
        this.pauseNotifyTimeout = null;
      }
    },

    accumulateExercisesTime(callBack, exercise, currentIndex) {
      const exerciseData = this.exersizeData(exercise);
      const repititionTime = exerciseData.duration.reduce((callback, item) => item + callback, 0);
      const breakTime = (currentIndex >= this.exercises.length - 1) ? (exerciseData.sets - 1) * exerciseData.pause : (exerciseData.sets) * exerciseData.pause;
      const exerciseTime = repititionTime * exerciseData.sets * exerciseData.reps + breakTime;

      return callBack + exerciseTime;
    },

    exersizeData(exercise) {
      return {
        title: exercise.title,
        sets: parseInt(exercise.sets) ? parseInt(exercise.sets) : 0,
        reps: parseInt(exercise.reps) ? parseInt(exercise.reps) : 0,
        pause: parseInt(exercise.pause) ? parseInt(exercise.pause) : 0,
        duration: [
          exercise.duration.charAt(0) && parseInt(exercise.duration.charAt(0)) ? parseInt(exercise.duration.charAt(0)) : 0,
          exercise.duration.charAt(1) && parseInt(exercise.duration.charAt(1)) ? parseInt(exercise.duration.charAt(1)) : 0,
          exercise.duration.charAt(2) && parseInt(exercise.duration.charAt(2)) ? parseInt(exercise.duration.charAt(2)) : 0,
          exercise.duration.charAt(3) && parseInt(exercise.duration.charAt(3)) ? parseInt(exercise.duration.charAt(3)) : 0
        ]
      }
    },

    startTimerAt(exerciseIndex, set) {
      this.stopTimer();
      this.timerPosition.exerciseIndex = exerciseIndex;
      this.timerPosition.set = set;
      this.setPreTimeout();
    },

    stopTimer() {
      this.resetTimerPosition();
      this.clearPauseNotifyTimeout();
      this.clearPreTimeout();
      this.clearTimeout();
    },

    pauseTimer() {
      this.clearPauseNotifyTimeout();
      this.clearPreTimeout();
      this.clearTimeout();
    },

    nextTimerInterval() {
      if (this.timerPosition.duration < 4) {
        this.timerPosition.duration++;
      } else if (this.timerPosition.rep < this.currentExcersizeData.reps) {
        this.timerPosition.rep++;
        this.timerPosition.duration = 1;
      } else if (this.timerPosition.set < this.currentExcersizeData.sets) {
        if (this.timerPosition.pause) {
          this.timerPosition.pause = false;
          this.timerPosition.set++;
          this.timerPosition.rep = 1;
          this.timerPosition.duration = 1;
        } else {
          this.timerPosition.pause = true;
        }
      } else if (this.exercises.length - 1> this.timerPosition.exerciseIndex) {
        if (this.timerPosition.pause) {
          this.timerPosition.pause = false;
          this.timerPosition.exerciseIndex++;
          this.timerPosition.set = 1;
          this.timerPosition.rep = 1;
          this.timerPosition.duration = 1;
        } else {
          this.timerPosition.pause = true;
        }
      } else {
        this.resetTimerPosition();
        return false;
      }

      return true;
    },

    makeSound(method) {
      switch (method) {
        case 'notify': 
          this.audio.src = Sounds.notify;
          break;
        case 'pause': 
          this.audio.src = Sounds.pause;
          break;
        case 'tick': 
          this.audio.src = Sounds.tick;
          break;
        case 'tickPrimary': 
          this.audio.src = Sounds.tickPrimary;
          break;
        case 'tickSecondary': 
          this.audio.src = Sounds.tickSecondary;
          break;
        case 'click': 
          this.audio.src = Sounds.click;
          break;
        default:
          this.audio.src = '';
          break;
      }

      this.audio.play();
    },

    resetTimerPosition() {
      this.timerPosition = {
        exerciseIndex: 0,
        set: 1,
        rep: 1,
        duration: 1,
        pause: false,
      };
    },

    setNextTimer(first = false) {
      if (!first) {
        if (!this.nextTimerInterval()) {
          this.makeSound('pause');
          this.stopTimer();
          return false;
        }
      }

      if (this.currentExcersizeData) {
        if (this.timerPosition.pause) {
          this.makeSound('pause');
          const sleepSeconds = this.currentExcersizeData.pause;

          if (sleepSeconds > 10) {
            this.pauseNotifyTimeout = setTimeout(() => {
              this.makeSound('notify');

              this.pauseNotifyTimeout = setTimeout(() => {
                this.makeSound('click');

                this.pauseNotifyTimeout = setTimeout(() => {
                  this.makeSound('click');

                  this.pauseNotifyTimeout = setTimeout(() => {
                    this.makeSound('click');
                  }, 1000);
                }, 1000);
              }, 7000);
            }, (sleepSeconds - 10) * 1000);
          }

          this.timerTimout = setTimeout(this.setNextTimer, sleepSeconds * 1000);
        } else {
          const sleepSeconds = this.currentExcersizeData.duration[this.timerPosition.duration - 1];
          if (sleepSeconds > 0) {
            this.timerTimout = setTimeout(this.setNextTimer, sleepSeconds * 1000);
            console.log('asdölkasölkd');
            if (this.timerPosition.duration == 1) {
              this.makeSound('tickPrimary');
            } else if (this.timerPosition.duration == 3) {
              this.makeSound('tickSecondary');
            } else {
              this.makeSound('tick');
            }
          } else {
            this.setNextTimer();
          }
        }
      }
    }
  }
}
</script>