<template>
  <v-card flat :elevation="(marked) ? 24 : 0" outlined>
    <v-container>
      <v-row>
        <v-col cols="12" md="3" sm="6">
          <v-text-field :value="value.title" @change="update('title', $event)" dense label="Exercise" outlined hide-details/>
        </v-col>

        <v-col cols="12" md="1" sm="3">
          <v-text-field :value="value.sets" @change="update('sets', $event)" label="Sets" dense outlined hide-details/>
        </v-col>

        <v-col cols="12" md="1" sm="3">
          <v-text-field :value="value.reps" @change="update('reps', $event)" label="Reps" dense outlined hide-details/>
        </v-col>

        <v-col cols="12" md="2" sm="6">
          <v-text-field :value="value.duration" @change="update('duration', $event)" label="Execution (Seconds)" placeholder="3010" dense outlined hide-details/>
        </v-col>

        <v-col cols="12" md="2" sm="6">
          <v-text-field :value="value.pause" @change="update('pause', $event)" label="Break (Seconds)" dense outlined hide-details/>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-btn color="warning" outlined @click="$emit('remove')" v-if="!denyRemoval">Remove</v-btn>

          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined v-bind="attrs" v-on="on" class="ml-2">
                <v-icon dark>mdi-play-circle-outline</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="set in sets" :key="'currentset' + index + '-' + set" @click="startExerciseAt(index, set)">
                <v-list-item-title>Begin set {{ set }} <v-icon right>mdi-av-timer</v-icon></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'Exercise',
  
  props: ['value', 'index', 'marked', 'deny-removal'],

  computed: {
    sets() {
      return parseInt(this.value.sets) ? parseInt(this.value.sets) : 0
    }
  },

  methods: {
    update(method, event) {
      const newObj = Object.assign({}, this.value);
      newObj[method] = event;
      this.$emit('input', newObj);
    },

    startExerciseAt(index, set) {
      this.$emit('start-exercise-at', {exerciseIndex: index, set});
    }
  }
}
</script>
