<template>
  <v-container>
    <v-row>
      <template v-for="(exercise, index) in exercises">
        <v-slide-y-transition :key="index" appear>
          <v-col cols="12">
            <Exercise :value="exercise" @remove="removeExercise(index)" @input="updateExcercise(index, $event)" :index="index" @start-exercise-at="startExerciseAt" :marked="index === exerciseIndex" :deny-removal="exercises.length === 1" />
          </v-col>
        </v-slide-y-transition>
      </template>

      <v-col cols="12">
        <v-btn @click="addExercise" color="primary" outlined class="mr-4"><v-icon left>mdi-plus</v-icon>Add Exercise</v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn @click="exportAsJson" color="secondary" outlined class="mr-2"><v-icon left>mdi-download-outline</v-icon>Export exercises</v-btn>
        <input ref="fileupload" type="file" :style="{display: 'none'}" @change="newFileUpload" />
        <v-btn @click="uploadJson" color="secondary" outlined class="mr-2"><v-icon left>mdi-upload-outline</v-icon>Import exercises</v-btn>
      </v-col>
    </v-row>

    <ExerciseTimer :exercises="exercises" ref="exerciseTimer"/>
    <a @click="loadTraining" :style="{opacity: 0}">-</a>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Exercise from './Exercise';
import ExerciseTimer from './ExerciseTimer';

  export default {
    components: {
      ExerciseTimer,
      Exercise
    },

    data: () => ({
      isMounted: false,
      exercises: [
        // {title: 'A: ', sets: '2', reps: '2', pause: '11', duration: '2020'},
        // {title: 'A: ', sets: '2', reps: '2', pause: '11', duration: '2020'},
        // {title: 'A: ', sets: '2', reps: '2', pause: '11', duration: '2020'},

        {title: 'A: DB Romanian Deadlift', sets: '3', reps: '10', pause: '80', duration: '3121'},
        {title: 'B: Heel Elevated DB Squat', sets: '3', reps: '12', pause: '80', duration: '3130'},
        {title: 'C: Lying DB Leg Curl', sets: '4', reps: '12', pause: '70', duration: '2020'},
        {title: 'D: DB Bulgarian Split Squat (Quad Focus)', sets: '3', reps: '25', pause: '80', duration: '2020'},
        {title: 'E: Walking Lounged DB', sets: '4', reps: '16', pause: '80', duration: '2010'},

        // {title: 'A: Chin Up (Neutral Grip)', sets: '4', reps: '8', pause: '90', duration: '2010'},
        // {title: 'B: One Arm DB Row', sets: '3', reps: '10', pause: '80', duration: '2010'},
        // {title: 'C: Incline DB Row (Elbows Flared)', sets: '3', reps: '12', pause: '70', duration: '2011'},
        // {title: 'D: Staning DB Rear Delt Fly', sets: '4', reps: '12', pause: '60', duration: '2010'},
        // {title: 'E1: Incline DB Shrugs', sets: '4', reps: '10', pause: '70', duration: '2021'},
        // {title: 'E2: Incline DB Rear Delt Fly (Swings)', sets: '4', reps: '15', pause: '70', duration: '1010'},

        // {title: 'A: Reverse Bench Crunch', sets: '3', reps: '8', pause: '80', duration: '3010'},
        // {title: 'B: Laying Ball/Object Crunch', sets: '3', reps: '12', pause: '60', duration: '3031'},
        // {title: 'C: Crunch', sets: '3', reps: '12', pause: '60', duration: '2011'},
        // {title: 'D: Side Crunch', sets: '3', reps: '10', pause: '60', duration: '2010'},
        // {title: 'E: Plank', sets: '3', reps: '12', pause: '60', duration: '5000'},
      ]
    }),

    computed: {
      exerciseIndex() {
        if(this.isMounted) {
          return this.$refs.exerciseTimer.timerPosition.exerciseIndex;
        }

        return 0;
      }
    },

    mounted(){
      this.isMounted = true;
    },

    methods: {
      addExercise() {
        this.exercises.push({title: '', sets: '12', reps: '3', pause: '80', duration: '3010'});
      },

      updateExcercise(index, newExercise) {
        Vue.set(this.exercises, index, newExercise);
      },

      removeExercise(index) {
        this.exercises.splice(index, 1);
      },

      startExerciseAt({exerciseIndex, set}) {
        this.$refs.exerciseTimer.startTimerAt(exerciseIndex, set);
      },

      loadTraining() {
        this.exercises = [
          {title: 'A1: Chain Up (Wide Grip)', sets: '1', reps: '8', pause: '10', duration: '2010'},
          {title: 'A2: Seated DB Press', sets: '1', reps: '10', pause: '90', duration: '3020'},
          {title: 'A1: Chain Up (Wide Grip)', sets: '1', reps: '8', pause: '10', duration: '2010'},
          {title: 'A2: Seated DB Press', sets: '1', reps: '10', pause: '90', duration: '3020'},
          {title: 'A1: Chain Up (Wide Grip)', sets: '1', reps: '8', pause: '10', duration: '2010'},
          {title: 'A2: Seated DB Press', sets: '1', reps: '10', pause: '90', duration: '3020'},

          {title: 'B1: Incline DB Press', sets: '1', reps: '12', pause: '10', duration: '2010'},
          {title: 'B2: DB Pullover (Lat Focus)', sets: '1', reps: '10', pause: '80', duration: '3021'},
          {title: 'B1: Incline DB Press', sets: '1', reps: '12', pause: '10', duration: '2010'},
          {title: 'B2: DB Pullover (Lat Focus)', sets: '1', reps: '10', pause: '80', duration: '3021'},
          {title: 'B1: Incline DB Press', sets: '1', reps: '12', pause: '10', duration: '2010'},
          {title: 'B2: DB Pullover (Lat Focus)', sets: '1', reps: '10', pause: '80', duration: '3021'},

          {title: 'C1: Countdown DB Lateral Raise', sets: '1', reps: '13', pause: '10', duration: '2010'},
          {title: 'C2: Decline DB Chest Fly', sets: '1', reps: '12', pause: '80', duration: '3021'},
          {title: 'C1: Countdown DB Lateral Raise', sets: '1', reps: '13', pause: '10', duration: '2010'},
          {title: 'C2: Decline DB Chest Fly', sets: '1', reps: '12', pause: '80', duration: '3021'},
          {title: 'C1: Countdown DB Lateral Raise', sets: '1', reps: '13', pause: '10', duration: '2010'},
          {title: 'C2: Decline DB Chest Fly', sets: '1', reps: '12', pause: '80', duration: '3021'},

          {title: 'D1: Incline Bench DB Rear Delt Fly', sets: '1', reps: '15', pause: '10', duration: '2020'},
          {title: 'D2: Incline DB Row (Elbows In)', sets: '1', reps: '12', pause: '80', duration: '2011'},
          {title: 'D1: Incline Bench DB Rear Delt Fly', sets: '1', reps: '15', pause: '10', duration: '2020'},
          {title: 'D2: Incline DB Row (Elbows In)', sets: '1', reps: '12', pause: '80', duration: '2011'},
          {title: 'D1: Incline Bench DB Rear Delt Fly', sets: '1', reps: '15', pause: '10', duration: '2020'},
          {title: 'D2: Incline DB Row (Elbows In)', sets: '1', reps: '12', pause: '80', duration: '2011'}
        ];
      },

      exportAsJson() {
        const jsonData = this.exercises;
        let dataStr = JSON.stringify(jsonData);
        let dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);

        const date = new Date().toLocaleDateString();

        let exportFileDefaultName = 'exercises-' + date + '.json';

        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
      },

      uploadJson() {
        this.$refs.fileupload.value = null;
        this.$refs.fileupload.click();
      },

      onReaderLoad(event) {
        try {
          var exercises = JSON.parse(event.target.result);
          this.exercises = exercises;
        } catch (err) {
          console.log(err);
        }
      },

      newFileUpload(event) {
        try {
          var reader = new FileReader();
          reader.onload = this.onReaderLoad;
          reader.readAsText(event.target.files[0]);
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
</script>
